:root {
  --toastify-toast-width: 100%;
}

.Toastify {
  &__toast-container {
    &--top-center {
      @apply left-1/2 flex w-full translate-x-[-50%] flex-col items-center pt-6 text-center lg:w-fit lg:px-10 lg:pt-10;
    }
  }

  &__toast {
    @apply items-center gap-x-[15px] rounded-2xl border p-[15px];
    @media only screen and (max-width: 480px) {
      @apply mb-3;
    }
  }

  &__toast--success {
    @apply border-mint-green shadow-[0px_4px_10px_0px_rgba(15,183,62,0.30)];
  }

  &__toast--error {
    @apply border-error shadow-[0px_4px_10px_0px_rgba(255,0,0,0.30)];
  }

  &__toast-body {
    @apply flex-grow-0 p-0 text-left text-xs font-medium text-black lg:text-base;
  }

  &__close-button {
    @apply self-center text-black opacity-100;
    & > svg {
      @apply h-5 w-5;
    }
  }
}

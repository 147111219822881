@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./toast.scss";
@import "./celeb-drop-video-player.scss";
@import "./lightbox.scss";
@import "./info.scss";
@import "./shadcn-extended/index.scss";

@layer base {
  @font-face {
    font-family: "Beni";
    font-weight: 400;
    src: url("/fonts/Nois-Beni.woff") format("woff");
  }

  @font-face {
    font-family: "Monument-Grotes";
    font-weight: 300;
    src: local("Monument-Grotes"), url("/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Light-Trial.otf") format("woff2");
  }

  @font-face {
    font-family: "Monument-Grotes";
    font-weight: 400;
    src: local("Monument-Grotes"),
      url("/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Regular-Trial.otf") format("woff2");
  }

  @font-face {
    font-family: "Monument-Grotes";
    font-weight: 500;
    src: local("Monument-Grotes"),
      url("/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Medium-Trial.otf") format("woff2");
  }

  @font-face {
    font-family: "Monument-Grotes";
    font-weight: 700;
    src: local("Monument-Grotes"), url("/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Bold-Trial.otf") format("woff2");
  }

  @font-face {
    font-family: "Monument-Grotes-Mono";
    font-weight: 700;
    src: local("Monument-Grotes-Mono"), url("/fonts/CargoMonumentGroteskMono-Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Monument-Grotesk-Ultra";
    font-weight: 100;
    src: local("Monument-Grotesk-Ultra"), url("/fonts/ABCMonumentGrotesk-Ultra-Trial.woff2") format("woff2");
  }

  @font-face {
    font-family: "Icons";
    font-weight: 400;
    src: local("Icons"), url("/fonts/IconFont-Regular-0.9.3.woff2") format("woff2");
  }

  @font-face {
    font-family: "Happy-Times-at-the-ikob";
    font-weight: 400;
    font-style: italic;
    src: local("Happy-Times-at-the-ikob"),
      url("/fonts/Happy-Times-at-the-ikob/HappyTimesAtTheIkob-Italic.ttf") format("truetype");
  }

  html,
  body {
    font-family: "Inter", Roboto, Oxygen, Ubuntu, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  html {
    scroll-behavior: auto !important;
  }

  .background-video-container {
    transform: translate3d(0, 0, 0);

    .jwplayer.jw-flag-aspect-mode,
    .jw-error {
      height: 100% !important;
    }
  }

  li.active > a {
    color: #2259e0;
  }

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .swiper-pagination-progressbar-fill {
    &.bg-malachite {
      background-color: #0fb73e !important;
    }
  }

  [contenteditable] {
    outline: 0px solid transparent;
  }

  .custom-code-input > input {
    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      border: transparent !important;
    }
  }

  .custom-code-input > input,
  input.no-spinner-input {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0;
      /* <-- Apparently some margin are still there even though it's hidden */
    }
  }

  .custom-tel-input {
    .iti {
      width: 100%;
    }

    .iti__selected-flag {
      background-color: transparent !important;
    }

    input {
      letter-spacing: 0.05em;
    }

    .iti--container,
    .iti-mobile .iti--container {
      top: 0px !important;
      left: 0px !important;
      bottom: unset !important;
      right: unset !important;
      position: absolute !important;
      width: 100% !important;
      height: 225px;

      ul {
        width: 100% !important;
        border-radius: 0px 0px 5px 5px;
      }
    }
  }

  .location-select-wrapper {
    & > div {
      border: none;
      border-radius: none;
    }

    input:focus {
      --tw-ring-color: transparent;
    }
  }

  // hide arrows in number inputs
  /* Chrome, Safari, Edge, Opera */
  input.custom-number-input::-webkit-outer-spin-button,
  input.custom-number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input.custom-number-input[type="number"] {
    -moz-appearance: textfield;
  }

  input.custom-input-with-placeholder {
    &:not(:placeholder-shown) {
      & + div {
        visibility: hidden;
      }
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .blurred-section-row {
    position: relative;
    overflow: auto;

    .scrollable-content {
      position: relative;
      overflow: auto;
      width: 100%;
    }
  }

  .blurred-section-row::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 80%;
    top: 0;
    background: linear-gradient(to right, transparent, rgba(256, 256, 256, 0.457029) 60%, white 99%);
    z-index: 1;
    pointer-events: none;
  }
}

@layer utilities {
  /* Flex */
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// google recaptcha
.grecaptcha-badge {
  z-index: 9999999;
}

// scrollbar style
/* For WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.twitter-tweet {
  margin: 0 auto !important;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  &:hover {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.article-page {
  .article-markdown {
    font-weight: 400;

    blockquote * {
      color: #00d973;
      text-align: center;
    }

    a {
      color: #0f6bff;

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      margin: 15px auto;
      overflow: hidden;
      border-radius: 32px;
    }

    ul {
      padding-left: 1em;
    }

    ol {
      list-style-type: decimal;
      padding-left: 1em;
    }
  }

  .article-time-location {
    text-transform: uppercase;
    opacity: 0.4;
  }

  .article-video {
    .jwplayer.jw-flag-aspect-mode {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .article-slider {
    .embla__container {
      backface-visibility: hidden;
    }
  }
}

@media screen and (min-width: 769px) {
  .article-page {
    // element style
    .article-markdown {
      img {
        margin: 15px auto;
        overflow: hidden;
        border-radius: 32px;
      }

      ul {
        padding-left: 1em;
        list-style-type: circle;
        /* this should be the default style */
      }
    }

    // time & location
    .article-time-location {
      font-size: 1.1em;
      text-transform: uppercase;
      opacity: 0.4;
    }

    // typography
    .article-markdown {
      font-size: 22px;
      line-height: 26px;

      p,
      li {
        font-size: 20px;
        line-height: 26px;
      }

      blockquote * {
        font-size: 50px;
        line-height: 60px;
      }

      h1 {
        font-size: 100px;
        line-height: 110px;
      }

      h2 {
        font-size: 70px;
        line-height: 80px;
      }

      h3 {
        font-size: 50px;
        line-height: 60px;
        font-weight: 500;
      }

      h4 {
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;
      }

      h5 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }

      h6 {
        font-size: 14px;
        line-height: 20px;
      }
    }

    // spacing
    .article-content {
      & > * {
        margin-top: 50px;
        position: relative;

        &:first-child {
          margin-top: 0px;
        }
      }

      .article-markdown {
        & > * {
          margin-top: 50px;
        }

        &:first-child > * {
          &:first-child {
            margin-top: 0px;
          }
        }
      }

      .image-caption {
        margin-top: 16px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }

      .link-button {
        font-weight: 500;
        font-size: 25px;
        line-height: 25px;
        padding: 25px 50px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .article-page {
    // element style
    .article-markdown {
      img {
        margin: 15px auto;
        overflow: hidden;
        border-radius: 32px;
      }

      ul {
        padding-left: 1em;
        list-style-type: circle;
        /* this should be the default style */
      }
    }

    // time & location
    .article-time-location {
      font-size: 1.1em;
      text-transform: uppercase;
      opacity: 0.4;
    }

    // typography
    .article-markdown {
      font-size: 14px;
      line-height: 20px;

      p,
      li {
        font-size: 14px;
        line-height: 20px;
      }

      blockquote * {
        font-size: 25px;
        line-height: 30px;
      }

      h1 {
        font-size: 50px;
        line-height: 55px;
      }

      h2 {
        font-size: 34px;
        line-height: 38px;
      }

      h3 {
        font-size: 25px;
        line-height: 30px;
        font-weight: 500;
      }

      h4 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
      }

      h5 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }

      h6 {
        font-size: 10px;
        line-height: 14px;
      }
    }

    .article-content {
      & > * {
        margin-top: 40px;

        &:first-child {
          margin-top: -40px;
        }
      }

      .article-markdown > * {
        margin-top: 40px;
      }

      .image-caption {
        margin-top: 8px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
      }

      .link-button {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        padding: 12px 24px;
      }
    }
  }
}

.yarl__thumbnails_thumbnail {
  & .custom-thumbnail {
    border: 2px solid black;
  }

  &.yarl__thumbnails_thumbnail_active .custom-thumbnail {
    border: 2px solid #00d973 !important;
  }
}

.price-stroke {
  -webkit-text-stroke: 0.5px #fff;
  opacity: inherit;
  font-weight: bold;
  color: #667085;
}

.paginate {
  font-size: 14px;

  .paginate-item {
    overflow: hidden;
    text-align: center;
    min-width: 30px;
    height: 35px;
    width: auto;
    opacity: 0.5;
    font-size: 12px;
    font-weight: 700;
    transition: opacity, font-size 0.2s ease-in-out;

    &.paginate-item__active {
      opacity: 1;
      font-size: 18px;
    }
  }

  .paginate-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    height: 100%;
    width: 100%;
  }
}

.bg-storefront-bottom-banner {
  background: linear-gradient(299deg, rgba(15, 107, 255, 0.2) 0.11%, rgba(255, 255, 255, 0) 14.95%),
    linear-gradient(0deg, rgba(0, 217, 115, 0.2) -16.1%, rgba(255, 255, 255, 0) 20.34%),
    linear-gradient(29deg, rgba(255, 177, 243, 0.2) 3.11%, rgba(255, 255, 255, 0) 29.14%), #f1f1f1;
}

// custom datepicker style
.react-datepicker__month .react-datepicker__month-text {
  display: inline-block;
  width: 6rem;
  padding: 10px 5px;
  margin: 2px;
}

.react-datepicker-wrapper {
  width: 100%;
}

// custom simplebar style inside scrollwrapper
.scroll-wrapper {
  .simplebar-scrollbar {
    cursor: pointer;
  }
}

.scroll-wrapper.discover-scroll-wrapper {
  .simplebar-horizontal {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 9999px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 3.5rem);

    @media (min-width: 768px) {
      width: calc(100% - 10rem);
    }

    @media (min-width: 2048px) {
      width: calc(100% - 500px);
    }
  }
}

.scroll-wrapper.full-width-scroll-wrapper {
  .simplebar-horizontal {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 9999px;
    left: 0;
    width: 100%;
  }
}

.intercom-dfosxs,
.intercom-launcher {
  bottom: 70px !important;

  @media screen and (min-width: 1024px) {
    bottom: 75px !important;
  }
}

.intercom-messenger-frame {
  bottom: 130px !important;

  @media screen and (min-width: 1024px) {
    bottom: 135px !important;
  }
}

.datepicker-wrapper {
  & > .react-date-picker__wrapper {
    border: none;
  }

  .react-date-picker__inputGroup__input {
    resize: none;
    appearance: none;
    background-color: transparent;

    &:focus {
      border-color: transparent !important;
      // background-color: transparent;
      outline: none;
      box-shadow: none;
    }
  }
}

@layer base {
  :root {
    --background: 0 0% 98.82%;
    --foreground: #191414;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 217 100% 52.9%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: #808080;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.02%;
    --ring: 0 72.2% 50.6%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 72.2% 50.6%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

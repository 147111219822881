.info-page {
  .info-markdown {
    font-weight: 400;

    blockquote * {
      color: #00d973;
      text-align: center;
    }

    a {
      color: #0f6bff;

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      margin: 15px auto;
      overflow: hidden;
      border-radius: 32px;
    }

    ul {
      padding-left: 1em;
    }

    ol {
      list-style-type: decimal;
      padding-left: 1em;
    }
  }


  .info-video {
    .jwplayer.jw-flag-aspect-mode {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

@media screen and (min-width: 769px) {
  .info-page {

    // element style
    .info-markdown {
      img {
        margin: 15px auto;
        overflow: hidden;
        border-radius: 32px;
      }

      ul {
        padding-left: 1em;
        list-style-type: circle;
        /* this should be the default style */
      }
    }

    // typography
    .info-markdown {
      font-size: 22px;
      line-height: 26px;

      p,
      li {
        font-size: 20px;
        line-height: 26px;
      }

      blockquote * {
        font-size: 50px;
        line-height: 60px;
      }

      h1 {
        font-size: 100px;
        line-height: 110px;
      }

      h2 {
        font-size: 70px;
        line-height: 80px;
      }

      h3 {
        font-size: 50px;
        line-height: 60px;
        font-weight: 500;
      }

      h4 {
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;
      }

      h5 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }

      h6 {
        font-size: 14px;
        line-height: 20px;
      }
    }

    // spacing
    .info-content {
      .info-markdown {
        &>* {
          margin-top: 40px;
        }

        &:first-child>* {
          &:first-child {
            margin-top: 0px;
          }
        }
      }

      .image-caption {
        margin-top: 16px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }

      .link-button {
        font-weight: 500;
        font-size: 25px;
        line-height: 25px;
        padding: 25px 50px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .info-page {

    // element style
    .info-markdown {
      img {
        margin: 15px auto;
        overflow: hidden;
        border-radius: 32px;
      }

      ul {
        padding-left: 1em;
        list-style-type: circle;
        /* this should be the default style */
      }
    }

    // typography
    .info-markdown {
      font-size: 14px;
      line-height: 20px;

      p,
      li {
        font-size: 14px;
        line-height: 20px;
      }

      blockquote * {
        font-size: 25px;
        line-height: 30px;
      }

      h1 {
        font-size: 50px;
        line-height: 55px;
      }

      h2 {
        font-size: 34px;
        line-height: 38px;
      }

      h3 {
        font-size: 25px;
        line-height: 30px;
        font-weight: 500;
      }

      h4 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
      }

      h5 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }

      h6 {
        font-size: 10px;
        line-height: 14px;
      }
    }

    .info-content {
      .info-markdown>* {
        margin-top: 40px;
      }

      .image-caption {
        margin-top: 8px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
      }

      .link-button {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        padding: 12px 24px;
      }
    }
  }
}
